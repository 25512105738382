import { createStore } from 'vuex';
import { pageview } from 'vue-gtag';
import { products } from '../data/data.js';

export default createStore({
  state: {
    drawerOpen: false,
    queueShown: false,
    animationInProgress: false,
    activeProduct: {
      code: null,
      displayName: null,
    },
    shouldShowOffboarded: false,
  },
  mutations: {
    //drawer
    toggleDrawer(state) {
      if (!state.animationInProgress) {
        !state.drawerOpen && pageview('view_kp-nav'); // if drawer is closed fire pageview in GA
        if (!state.drawerOpen) {
          state.drawerOpen = true;
          setTimeout(() => {
            state.queueShown = true;
          }, 333);
        } else {
          state.queueShown = false;
          setTimeout(() => {
            state.drawerOpen = false;
          }, 333);
        }
      }
    },
    animationStarted(state) {
      state.animationInProgress = true;
    },
    animationEnded(state) {
      state.animationInProgress = false;
    },
    openDrawer(state) {
      state.drawerOpen = true;
    },
    closeDrawer(state) {
      state.drawerOpen = false;
    },
    showOffboarded(state) {
      state.shouldShowOffboarded = true;
    },
    hideOffboarded(state) {
      state.shouldShowOffboarded = false;
    },

    //activeProduct
    setActiveProduct(state, payload) {
      state.activeProduct = {
        code: payload,
        displayName: products[payload]?.displayName,
      };
    },
    clearActiveProduct(state) {
      state.activeProduct = {
        code: null,
        displayName: null,
      };
    },
  },
  actions: {},
  modules: {},
});
