let postMessageId = 0;
export function getCookieValue(name) {
  postMessageId++;
  const thisMessageId = postMessageId;

  const helperIframe = document.getElementById('kpnav_cookie-helper-iframe').contentWindow;

  return new Promise((resolve, reject) => {
    if (!window.location.hostname.endsWith('keypathedu.com')) {
      helperIframe.postMessage({ action: 'read', payload: name, id: thisMessageId }, '*');

      function listenForTheMessage(e) {
        const { postMessageId, cookieValue } = e.data;
        if (postMessageId === thisMessageId) {
          console.log('Async Cookie:', cookieValue);
          resolve(cookieValue);
        }
      }

      window.addEventListener('message', listenForTheMessage);

      setTimeout(() => {
        //timeout the listener if it hasn't happened 1 second later
        window.removeEventListener('message', listenForTheMessage);
        reject();
      }, 1000);
    } else {
      const theCookieVal = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || false;
      console.log('Synchronous Cookie:', theCookieVal);
      resolve(theCookieVal);
    }
  });
}

function distantFuture() {
  return 60 * 60 * 24 * 365 * 10;
}

export function returnCookieHeaders() {
  return `;max-age=${distantFuture()}; domain=keypathedu.com; samesite=none; path=/; secure`;
  //the line about  process.env.NODE_ENV === 'development' will allow for local dev and testing and will add the correct domain for build and deploy
}

export function setStackCookie(productCode, stackArray) {
  const cookieString = `kpnav_${productCode}=${JSON.stringify(stackArray)}${returnCookieHeaders()}`;
  return new Promise((resolve, reject) => {
    if (!window.location.hostname.endsWith('keypathedu.com')) {
      //postmessage trickery
      postMessageId++;
      const thisMessageId = postMessageId;

      const helperIframe = document.getElementById('kpnav_cookie-helper-iframe').contentWindow;
      helperIframe.postMessage({ action: 'write', payload: cookieString, id: thisMessageId }, '*');

      function listenForSuccess(e) {
        const { postMessageId, success } = e.data;
        if (postMessageId === thisMessageId) {
          if (success === true) {
            resolve(true);
          } else {
            reject();
          }
        }
      }
      window.addEventListener('message', listenForSuccess);

      setTimeout(() => {
        //timeout the listener if it hasn't happened 1 second later
        window.removeEventListener('message', listenForSuccess);
        reject();
      }, 1000);
    } else {
      document.cookie = cookieString;
      resolve();
    }
  });
}
