<template>
  <li class="kpnav_partner-link-row" :class="{ kpnav_disabled: disabled }">
    <a
      :href="url"
      class="kpnav_partner-link"
      :title="`${linkText} - Opens in same browser tab`"
      @click.prevent="confirmNav"
      @auxclick="newTabClickHandler"
    >
      {{ linkText }}
    </a>
    <a
      v-if="!disabled"
      :href="url"
      target="_blank"
      class="kpnav_new-tab-launcher-container"
      @mouseenter="showFlag"
      @focus="showFlag"
      @mouseleave="hideFlag"
      @blur="hideFlag"
      @click="newTabClickHandler"
      @auxclick="newTabClickHandler"
    >
      <span class="kpnav_new-tab-launcher-icon" :class="{ 'kpnav_red-bg': shouldShowflag }">
        <svg xmlns="http://www.w3.org/2000/svg" width="12.264" height="12.264" viewBox="0 0 12.264 12.264">
          <path
            d="M10.348,7.665H9.581a.383.383,0,0,0-.383.383v2.683H1.533V3.066H4.982a.383.383,0,0,0,.383-.383V1.916a.383.383,0,0,0-.383-.383H1.15A1.15,1.15,0,0,0,0,2.683v8.431a1.15,1.15,0,0,0,1.15,1.15H9.581a1.15,1.15,0,0,0,1.15-1.15h0V8.048A.383.383,0,0,0,10.348,7.665ZM11.689,0H8.623a.576.576,0,0,0-.407.982l.856.856L3.234,7.674a.575.575,0,0,0,0,.813h0l.543.542a.575.575,0,0,0,.813,0h0l5.835-5.837.856.855a.576.576,0,0,0,.982-.407V.575A.575.575,0,0,0,11.689,0Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <transition name="flagslide">
        <span v-if="shouldShowflag" class="kpnav_new-tab-launcher-popflag">
          <span>
            Open in new tab
          </span>
        </span>
      </transition>
    </a>
  </li>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { getCookieValue, setStackCookie } from '../utils.js';

const props = defineProps({
  linkText: String,
  url: String,
  disabled: Boolean,
  partnerCode: String,
});

const emit = defineEmits(['open-partner-link']);

const store = useStore();

const activeProductCode = computed(() => {
  return store.state.activeProduct.code;
});

function confirmNav(e) {
  const linkUrl = e.target.getAttribute('href');

  if (window.confirm('Leave current page?')) {
    window.location.href = linkUrl;
  }
}

const shouldShowflag = ref(false);

function showFlag() {
  shouldShowflag.value = true;
}

function hideFlag() {
  shouldShowflag.value = false;
}

function newTabClickHandler(e) {
  hideFlag();
  addToCookieHistory();
}

const maxStackLength = 4;

async function addToCookieHistory() {
  if (!props.partnerCode) {
    return console.warn('No PARTNER code set, cannot track recent partners');
  }

  if (!activeProductCode) {
    return console.warn('No active PRODUCT code set, cannot track recent partners');
  }

  console.log('Setting history for', activeProductCode.value, ' : ', props.partnerCode);

  const currentStackCookie = await getCookieValue(`kpnav_${activeProductCode.value}`);

  const stackArray = currentStackCookie ? JSON.parse(currentStackCookie) : [];

  if (stackArray[0] !== props.partnerCode) {
    stackArray.unshift(props.partnerCode);
  }

  const uniqueStackArray = [...new Set(stackArray)];

  if (uniqueStackArray.length > maxStackLength) {
    uniqueStackArray.length = maxStackLength;
  }

  await setStackCookie(activeProductCode.value, uniqueStackArray);
  emit('open-partner-link');
}
</script>

<style lang="scss" scoped>
@import '../variables.scss';

#kpnav_app {
  //nesting needed for specificity since this will be deployed in same context of other apps

  .kpnav_partner-link-row {
    display: grid;
    grid-template-columns: 1fr auto;
    min-height: 2em;
    margin-bottom: 0.5em;
    box-sizing: border-box;
    gap: 1em;

    &.kpnav_disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.2);
      .kpnav_partner-link {
        color: $secondary-keypath;
      }
    }
  }

  .kpnav_partner-link {
    text-decoration: none;
    color: $dark;
    font-family: $font;
    padding: 0.5em;
  }

  .kpnav_new-tab-launcher-container {
    position: relative;
    width: 2.2em;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
  }

  .kpnav_new-tab-launcher-icon {
    background: $light-grey;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-keypath;
    position: relative;
    z-index: 30;

    &.kpnav_red-bg {
      background: #e85244;
      color: white;
    }
  }

  .kpnav_new-tab-launcher-popflag {
    display: flex;
    background: #e85244;
    color: white;
    position: absolute;
    box-sizing: border-box;
    z-index: 2;
    right: 100%;
    top: 0;
    white-space: nowrap;
    height: 100%;
    width: 7em;
    padding: 0 0.5em;
    align-items: center;
    font-family: $font;
    overflow: hidden;
    transition: width 0.25s;

    span {
      font-size: 0.8em;
      font-family: $font; //needed for Keyosk conflict
    }
  }

  .flagslide-enter-active,
  .flagslide-leave-active {
    transition: transform 0.25s;
  }

  .flagslide-enter-from,
  .flagslide-leave-to {
    transform: translateX(50%) scaleX(0);
  }
}
</style>
