<template>
  <nav>
    <Link v-if="productUrl" :linkText="productText + ' Home'" :url="productUrl" />

    <div class="kpnav_recent-partners" v-if="recentList.length > 0">
      <div class="kpnav_list-seperator-with-button">
        <span>Recent Partners</span>
        <div class="kpnav_line"></div>
        <button class="kpnav_clear-history" @click.prevent="clearHistory">Clear History</button>
      </div>
      <ul>
        <Link
          class="kpnav_recent-link"
          v-for="recentPartner in recentList"
          :key="recentPartner.partnerCode"
          :linkText="recentPartner.text"
          :url="recentPartner.url"
          :disabled="recentPartner.disabled"
          :partnerCode="recentPartner.partnerCode"
          @open-partner-link="grabHistoryCookies"
        />
      </ul>
    </div>

    <div v-if="recentList.length > 0 || productUrl" class="kpnav_list-seperator">
      <span>All Partners</span>
    </div>
    <div id="kpnav_filterer-container">
      <input
        id="kpnav_filterer"
        v-model="filterer"
        @input="filterProductLinks"
        aria-label="Filter Partners by typing"
        placeholder="Start typing partner to filter"
      />
      <div v-if="filteredProductLinks.length === 0" id="kpnav_no-filter-results">
        <div>No results for filter term</div>
        <button id="kpnav_clear-filter" @click="resetFilterer">Reset filter</button>
      </div>
    </div>
    <ul class="kpnav_partner-links">
      <Link
        class="kpnav_all-partner-link"
        v-for="partnerLink in filteredProductLinks"
        :key="partnerLink.text"
        :linkText="partnerLink.text"
        :url="partnerLink.url"
        :disabled="partnerLink.disabled"
        :partnerCode="partnerLink.partnerCode"
        @open-partner-link="grabHistoryCookies"
      />
    </ul>
  </nav>
  <div class="additional-options">
    <label id="offboarded-label">
      <input id="offboarded-checkbox" type="checkbox" v-model="shouldShowModel" @change="filterProductLinks" />
      Show offboarded partners
    </label>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Link from './Link.vue';
import { partners, products } from '../data/data.js';
import { getCookieValue, setStackCookie } from '../utils.js';

const props = defineProps({
  product: String,
});

const store = useStore();

const partnerLinks = ref([]);

const filterer = ref('');
function resetFilterer() {
  filterer.value = '';
  filterProductLinks();
}
const lowerFilterer = computed(() => filterer.value.toLowerCase().trim());

const shouldShowModel = computed({
  get() {
    return store.state.shouldShowOffboarded;
  },
  set(value) {
    console.log('setting', value);
    if (value === true) {
      store.commit('showOffboarded');
    } else {
      store.commit('hideOffboarded');
    }
  },
});

function filterProductLinks(e) {
  partnerLinks.value.forEach((link) => {
    let passesFilter = false;
    for (const word of link.words) {
      if (word.startsWith(lowerFilterer.value) || lowerFilterer.value === '') {
        passesFilter = true;
        break;
      }
    }
    link.passesFilter = passesFilter;

    //additional offboarded filter
    if (store.state.shouldShowOffboarded === false && link.offboarded === true) {
      link.passesFilter = false;
    }
  });
}

const filteredProductLinks = computed(() => partnerLinks.value.filter((link) => link.passesFilter === true));

const productUrl = products[props.product].homepageLink;
const productText = products[props.product].displayName;

//recent partners

const activeProductCode = computed(() => {
  return store.state.activeProduct.code;
});

const recentList = ref([]);

async function grabHistoryCookies() {
  const currentStackCookie = await getCookieValue(`kpnav_${activeProductCode.value}`);
  const stack = currentStackCookie ? JSON.parse(currentStackCookie) : [];
  if (stack.length > 0) {
    recentList.value = stack.map((code) => {
      return { ...partnerLinks.value.find((partnerLink) => partnerLink.partnerCode === code) };
    });
  } else {
    recentList.value = [];
  }
}

onMounted(async () => {
  for (let partner in partners) {
    const partnerObj = partners[partner];

    let urlFormulaProducts = ['gizmo', 'phonehome', 'keyosk', 'cqr'];
    let disabledStatus;
    if (!urlFormulaProducts.includes(props.product) && !partnerObj.links[props.product]) {
      disabledStatus = true;
    } else {
      disabledStatus = false;
    }

    let url;
    if (props.product === 'gizmo') {
      url = `${products.gizmo.homepageLink}?university=${partner}`;
    } else if (props.product === 'phonehome') {
      url = `${products.phonehome.partnerPageLink}${partner}%27`; //this %27 is important, do not remove
      if (partnerObj.noPhoneHome === true) {
        disabledStatus = true;
      }
    } else if (props.product === 'keyosk') {
      url = `${products.keyosk.partnerPageLink}${partner}`;
    } else if (props.product === 'cqr') {
      url = `${products.cqr.homepageLink}?partner=${partner}`;
    } else {
      url = partnerObj.links[props.product];
    }

    partnerLinks.value.push({
      text: partnerObj.displayName,
      url: url,
      disabled: disabledStatus,
      partnerCode: partner,
      passesFilter: true,
      words: partnerObj.displayName.split(' ').map((word) => word.replace(/\(|\)/g, '').toLowerCase()),
      offboarded: partnerObj.offboarded,
    });
  }
  resetFilterer();
  await grabHistoryCookies();
});

async function clearHistory() {
  await setStackCookie(activeProductCode.value, []);
  await grabHistoryCookies();
  alert(`History cleared for: ${products[props.product].displayName}`);
}
</script>

<style lang="scss" scoped>
@import '../variables.scss';

#kpnav_app {
  //nesting needed for specificity since this will be deployed in same context of other apps
  ul {
    list-style: none;
    padding: 0;
    margin-top: 0;
  }

  .kpnav_list-seperator {
    display: flex;
    align-items: center;
    color: #302e2e;
    font-family: $font;
    margin-bottom: 0.8em;
    gap: 1em;

    span {
      font-size: 0.6em;
    }

    &::after {
      content: '';
      height: 1px;
      background: $light-grey;
      display: block;
      flex: 1;
      transform: translateY(0.15em);
    }
  }

  .kpnav_list-seperator-with-button {
    display: flex;
    align-items: center;
    color: #302e2e;
    font-family: $font;
    font-size: 0.6em;
    margin-bottom: 0.8em;
    gap: 1em;

    span,
    button {
      font-size: inherit;
    }

    .kpnav_line {
      content: '';
      height: 1px;
      background: $light-grey;
      display: block;
      flex: 1;
      transform: translateY(0.15em);
    }
  }

  #kpnav_filterer-container {
    margin: 0 0 0.7em 0;
  }

  #kpnav_no-filter-results {
    background: $light-grey;
    width: 100%;
    padding: 0.3em 0.5em;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div,
    button {
      font-size: 0.7em;
    }

    button {
      background: white;
      border: $primary-keypath 1px solid;

      &:hover {
        background: $primary-keypath;
        color: white;
        cursor: pointer;
      }
    }
  }

  #kpnav_filterer {
    border: none;
    border: 2px solid $light-grey;
    background: white;
    padding: 0.7em;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    font-family: $font;
  }

  .kpnav_clear-history {
    font-size: 1em;
    padding: 0.4em;
    background: $light-grey;
    border: none;
    color: $primary-keypath;

    &:focus,
    &:hover {
      background: #e85244;
      color: white;
      cursor: pointer;
    }
  }
}

.additional-options {
  background-color: #ece9e8;
  padding: 10px 5px;
  margin: 10px 0;
}

#offboarded-label {
  color: black;
}

#offboarded-checkbox {
  margin: 0;
  padding: 0;
  height: 20px; 
  width: 20px;
}
</style>
