<template>
  <section class="jira-issue_status">
    <div class="status-bar" :class="currentRatio()">
      <span class="status-bar_text">{{ statusText }}</span>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import axios from 'axios';

const urlSearchParams = new URLSearchParams(window.location.search);
const urlParams = Object.fromEntries(urlSearchParams.entries());

let debug = urlParams['kp-nav_debug'];

const statusText = computed(() => {
  let textContent = {
    green: '🙌 High',
    yellow: '🙂 Low Availability',
    red: '🐢 Running Late',
    white: '🤔 Checking Queue',
  };
  // Return verbiage according to status of WD queue
  return textContent[currentRatio()];
});

function currentRatio() {
  return 'yellow';
}
</script>

<style scoped>
.jira-issue_status {
  margin: 0 -5px;
}

.status-bar {
  align-items: center;
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.2);
  color: #333;
  display: flex;
  height: 45px;
  justify-content: center;
  width: 100%;
}

.status-bar_text {
  font-size: 18px;
  text-transform: uppercase;
}

.status-bar.green {
  background-color: #43a047;
  color: #fff;
}

.status-bar.yellow {
  background-color: #ffeb3b;
}

.status-bar.red {
  background-color: #e53935;
  color: #fff;
}
</style>
